import type { SandpackPredefinedTemplate } from '@codesandbox/sandpack-react';
import {
  SandpackConsole,
  SandpackPreview,
  SandpackStack,
  SandpackTests,
} from '@codesandbox/sandpack-react';

import { SplitPane } from '~/components/lib/splite-pane/SplitPane';
import type { PlaygroundModeType } from './MdxPlaygroundPropsSchema';
import { SandpackPlaygroundConsoleAndPreview } from './SandPackPlaygroundConsoleAndPreview';
import { SandpackConsoleStyled } from './SandpackConsoleStyled';

const NODEJS_TEMPLATE = [
  'node',
  'nextjs',
  'vite',
  'vite-react',
  'vite-react-ts',
  'vite-preact',
  'vite-preact-ts',
  'vite-vue',
  'vite-vue-ts',
  'vite-svelte',
  'vite-svelte-ts',
  'astro',
] satisfies SandpackPredefinedTemplate[];

type SecondPaneProps = {
  template: SandpackPredefinedTemplate;
  mode: PlaygroundModeType;
  defaultTab: 'console' | 'preview';
  showNavigator?: boolean;
};

export const SandpackSecondPane = ({
  template,
  defaultTab,
  showNavigator,
  mode,
}: SecondPaneProps) => {
  const isTest = template.includes('test');
  const isNode = NODEJS_TEMPLATE.includes(template);

  const Console = isNode ? SandpackConsole : SandpackConsoleStyled;

  if (isTest) {
    if (mode === 'both') {
      return (
        <SandpackStack className="h-full">
          <SplitPane direction="vertical" defaultFirstRatio={0.6}>
            <SandpackTests style={{ height: '100%' }} />
            <Console />
          </SplitPane>
        </SandpackStack>
      );
    }

    if (mode === 'console') {
      return (
        <SandpackStack className="h-full">
          <div className="hidden">
            <SandpackTests style={{ height: '0px' }} />
          </div>
          <SandpackConsoleStyled />
        </SandpackStack>
      );
    }

    return <SandpackTests style={{ height: '100%' }} />;
  }

  if (mode === 'both-tabs') {
    return <SandpackPlaygroundConsoleAndPreview defaultTab={defaultTab} />;
  }

  const sandpackPreviewProps = {
    showNavigator,
  };

  if (mode === 'console') {
    return (
      <SandpackStack className="h-full">
        <SandpackConsoleStyled className="h-full" />
        <SandpackPreview {...sandpackPreviewProps} style={{ display: 'none' }} />
      </SandpackStack>
    );
  }

  if (mode === 'both') {
    return (
      <SandpackStack className="h-full">
        <SplitPane direction="vertical" defaultFirstRatio={0.6}>
          <SandpackPreview
            {...sandpackPreviewProps}
            style={{ height: '100%' }}
            className="overflow-hidden"
          />
          <Console />
        </SplitPane>
      </SandpackStack>
    );
  }

  return <SandpackPreview {...sandpackPreviewProps} style={{ height: '100%' }} />;
};
