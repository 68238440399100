// crypto.js is supported in the browser and node.js
import { SHA256 } from 'crypto-js';

export const hashObj = (obj: Record<string, unknown>) => {
  const data = JSON.stringify(obj);

  const hashedData = SHA256(data).toString();

  return hashedData;
};
