import { z } from 'zod';
import {
  HeightKeys,
  PlaygroundModeKeys,
} from '../playground-v2/mdx-playground-v2.const';

export const PlaygroundMode = z
  .enum(PlaygroundModeKeys)
  .optional()
  .default('preview');

export type PlaygroundModeType = z.infer<typeof PlaygroundMode>;

export const FilesSchema = z.record(
  z.string().or(
    z.object({
      code: z.string(),
      hidden: z.boolean().optional(),
      active: z.boolean().optional(),
      readOnly: z.boolean().optional(),
      deleted: z.boolean().optional(),
    })
  )
);

export type FileSchemaType = z.infer<typeof FilesSchema>;

export const MdxPlaygroundPropsSchema = z.object({
  template: z
    .union([
      z.literal('static'),
      z.literal('angular'),
      z.literal('react'),
      z.literal('react-ts'),
      z.literal('solid'),
      z.literal('svelte'),
      z.literal('test-ts'),
      z.literal('vanilla-ts'),
      z.literal('vanilla'),
      z.literal('vue'),
      z.literal('vue-ts'),
      z.literal('node'),
      z.literal('nextjs'),
      z.literal('vite'),
      z.literal('vite-react'),
      z.literal('vite-react-ts'),
      z.literal('vite-vue'),
      z.literal('vite-vue-ts'),
      z.literal('vite-svelte'),
      z.literal('vite-svelte-ts'),
      z.literal('astro'),
    ])
    .optional()
    .default('react'),
  externals: z.array(z.string()).optional(),
  files: z.record(
    z.string().or(
      z.object({
        code: z.string(),
        hidden: z.boolean().optional(),
        active: z.boolean().optional(),
        readOnly: z.boolean().optional(),
        deleted: z.boolean().optional(),
      })
    )
  ),
  activeFile: z.string().optional(),
  dependencies: z.record(z.string()).optional(),
  disableSsr: z.coerce.boolean().optional().default(false),
  showLineNumbers: z.coerce.boolean().optional().default(false),
  showNavigator: z.coerce.boolean().optional().default(false),
  tailwind: z.coerce.boolean().optional().default(false),
  title: z.string().optional().default('Playground'),
  showRefreshButton: z.boolean().optional().default(true),
  visibleFiles: z.array(z.string()).optional(),
  defaultTab: z
    .union([z.literal('console'), z.literal('preview')])
    .optional()
    .default('preview'),
  orientation: z
    .union([z.literal('auto'), z.literal('horizontal'), z.literal('vertical')])
    .default('auto')
    .optional(),
  size: z
    .union([
      z.literal('extraSmall'),
      z.literal('small'),
      z.literal('medium'),
      z.literal('large'),
    ])
    .default('medium'),
  height: z.enum(HeightKeys).optional(),
  componentId: z.string().optional(),
  previewSize: z
    .union([z.literal('small'), z.literal('medium'), z.literal('large')])
    .optional()
    .default('medium')
    .transform((value) => {
      switch (value) {
        case 'small':
          return 0.2;
        case 'medium':
          return 0.4;
        case 'large':
          return 0.6;
        default:
          return 0.25;
      }
    }),

  // @deprecated use mode instead
  showConsole: z.coerce.boolean().optional().default(false),
  onlyConsole: z.coerce.boolean().optional().default(false),
  hideEditor: z.coerce.boolean().optional().default(false),
  mode: PlaygroundMode,
});

export type MdxPlaygroundProps = z.infer<typeof MdxPlaygroundPropsSchema>;
