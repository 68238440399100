export const HeightKeys = ['xs', 'sm', 'default', 'lg', 'auto'] as const;
export type HeightKeys = (typeof HeightKeys)[number];

export const HeightMapping: Record<HeightKeys, number | null> = {
  xs: 300,
  sm: 400,
  default: 500,
  lg: 700,
  auto: null,
};

export const PlaygroundModeKeys = [
  'both',
  'both-tabs',
  'console',
  'preview',
] as const;
