import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type PanelType = 'comments' | 'ai' | 'notes' | 'notebook' | 'settings' | null;

export const usePanelStore = create(
  persist<{
    panel: PanelType;
    setPanel: (panel: PanelType) => void;
  }>(
    (set) => ({
      panel: null,
      setPanel: (panel) =>
        set((prev) => ({ panel: prev.panel === panel ? null : panel })),
    }),
    {
      name: 'panel-store',
    }
  )
);
