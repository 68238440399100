import clsx from 'clsx';
import type { MouseEventHandler, ReactElement } from 'react';
import { useEffect, useRef, useState } from 'react';

export const SplitPane = ({
  children,
  direction = 'horizontal',
  defaultFirstRatio = 0.5,
  height,
}: {
  children: [ReactElement, ReactElement];
  direction?: 'horizontal' | 'vertical';
  defaultFirstRatio?: number;
  height?: number | string;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isMoving, setIsMoving] = useState(false);
  const [firstRatioPercentage, setFirstRationPercentage] =
    useState(defaultFirstRatio);

  const onMouseMove: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!isMoving) {
      return;
    }

    const {
      left,
      top,
      width,
      height: elementHeight,
    } = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - left;
    const y = e.clientY - top;

    const ratio = direction === 'horizontal' ? x / width : y / elementHeight;
    setFirstRationPercentage(ratio);
  };

  const firstFlex = Math.round(firstRatioPercentage * 982);
  const secondFlex = 982 - firstFlex;

  useEffect(() => {
    const onMouseUp = () => {
      setIsMoving(false);
    };

    window.addEventListener('mouseup', onMouseUp);

    return () => {
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, []);

  return (
    <div
      ref={ref}
      className={clsx('relative flex size-full', {
        'flex-col': direction === 'vertical',
      })}
      style={{
        height,
      }}
    >
      {isMoving && (
        <div
          className="absolute inset-0 z-50 bg-gray-10/20"
          ref={ref}
          onMouseMove={(e) => {
            e.preventDefault();
            onMouseMove(e);
          }}
        ></div>
      )}
      <div
        style={{
          flex: firstFlex,
          width: direction === 'horizontal' ? 100 : '100%',
          height: direction === 'horizontal' ? '100%' : 100,
        }}
      >
        {children[0]}
      </div>
      <div
        onMouseDown={() => {
          setIsMoving(true);
        }}
        style={{
          background: isMoving
            ? 'var(--sp-colors-surface2)'
            : 'var(--sp-colors-active)',
        }}
        className={clsx('', {
          'h-1.5 w-full': direction === 'vertical',
          'h-full w-1.5': direction === 'horizontal',
          'outline outline-offset-2 outline-sky-70': isMoving,
        })}
      ></div>
      <div
        style={{
          flex: secondFlex,
          width: direction === 'horizontal' ? 100 : '100%',
          height: direction === 'horizontal' ? '100%' : 100,
        }}
      >
        {children[1]}
      </div>
    </div>
  );
};
