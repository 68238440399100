import { useSandpack } from '@codesandbox/sandpack-react';
import { useEffect, useRef } from 'react';
import { useEffectOnce } from 'react-use';
import { useLocalStorage } from 'usehooks-ts';
import { BadgeButton } from '~/components/catalyst/badge-catalyst';
import { Tooltip } from '~/components/ui/tooltip';
import type { FileSchemaType } from './MdxPlaygroundPropsSchema';

export const MdxPlaygroundSaveCode = (props: { componentId: string }) => {
  const { sandpack } = useSandpack();
  const { files, activeFile } = sandpack;
  const initialFiles = useRef(files);
  const [localFiles, setLocalFiles] = useLocalStorage<FileSchemaType | null>(
    `files-${props.componentId}`,
    null
  );
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    // make a deep comparison of the files
    if (JSON.stringify(initialFiles.current) === JSON.stringify(files)) {
      return;
    }

    setLocalFiles(files);
  }, [files, setLocalFiles, activeFile]);

  useEffectOnce(() => {
    setTimeout(() => {
      if (localFiles) {
        for (const file in localFiles) {
          sandpack.updateFile(
            file,
            typeof localFiles[file] === 'string'
              ? localFiles[file]
              : localFiles[file].code,
            true
          );
        }
      }
    }, 500);
  });

  return null;
};

export const IsSavedIndicator = (props: { componentId: string }) => {
  const [savedFiles] = useLocalStorage<FileSchemaType | null>(
    `files-${props.componentId}`,
    null
  );
  if (!savedFiles) {
    return null;
  }

  return (
    <Tooltip title="Your edit is saved inside local storage on your computer.">
      <BadgeButton color="green">Saved</BadgeButton>
    </Tooltip>
  );
};
