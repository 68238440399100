import { Send, Video, X } from 'lucide-react';
import { useEffect, useRef } from 'react';
import { create } from 'zustand';
import { Badge } from '~/components/ui/badge';
import { Button } from '~/components/ui/button';
import { Typo } from '~/components/ui/typography';
import { formatId } from '~/lib/format/formatId';

type VideoType = {
  id: string;
  title?: string;
};

export const useAIChatFormValue = create<{
  value: string;
  setValue: (value: string) => void;
  video: VideoType | null;
  setVideo: (videoId: VideoType | null) => void;
}>((set) => ({
  value: '',
  video: null,
  setVideo: (video) => set({ video }),
  setValue: (value) => set({ value }),
}));

export const AIChatForm = ({
  onSubmit,
  loading,
}: {
  onSubmit: () => void;
  loading?: boolean;
}) => {
  const { value, setValue, video, setVideo } = useAIChatFormValue();

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <div className="relative flex flex-col gap-2">
      {video ? (
        <div className="absolute -top-6 right-0">
          <Badge variant="outline" className="inline-flex items-center gap-2">
            <Video size={12} />
            <span className="max-w-24 truncate">
              {formatId(video.title ?? video.id)}
            </span>
            <button onClick={() => setVideo(null)}>
              <X size={12} />
            </button>
          </Badge>
        </div>
      ) : null}
      <div className="flex items-start gap-1 lg:flex-col">
        <AutoResizeTextarea
          isPending={loading ?? false}
          value={value}
          onChange={(v) => setValue(v)}
          onSubmit={() => {
            handleSubmit();
          }}
        ></AutoResizeTextarea>
        <Typo variant="muted" className="hidden lg:block">
          Press <Typo variant={'code'}>enter</Typo> to submit.
        </Typo>
        <Button size="sm" variant="outline" className="lg:hidden">
          <Send size={16} />
        </Button>
      </div>
    </div>
  );
};

const AutoResizeTextarea = ({
  isPending,
  onSubmit,
  value,
  onChange,
}: {
  isPending: boolean;
  onSubmit: (input: string) => void;
  value: string;
  onChange: (v: string) => void;
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.current.style.height = '0px';

      const scrollHeight = textAreaRef.current.scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [textAreaRef, value]);

  return (
    <textarea
      ref={textAreaRef}
      className="hide-scrollbar flex w-full resize-none overflow-hidden rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
      disabled={isPending}
      name="message"
      value={value}
      rows={1}
      placeholder="Type a message"
      onChange={(e) => {
        onChange(e.target.value);
      }}
      onKeyDown={(e) => {
        if (isPending) return;

        if (e.key === 'Enter') {
          onSubmit(value);
        }
      }}
    />
  );
};
