'use client';

import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import MarkdownToJSX from 'markdown-to-jsx';
import type { ComponentPropsWithoutRef } from 'react';
import rehypeRaw from 'rehype-raw';
import { getSingletonHighlighter } from 'shiki/index.mjs';
import ErrorBoundary from '~/components/utils/ErrorBoundaries';
import { useTheme } from '~/features/theme/ThemeProvider';
import { rehypePlugins } from '~/lib/markdown-plugin/rehypePlugins';

export const Markdown = ({
  enableRaw,
  ...props
}: {
  children: string;
  enableRaw?: boolean;
} & ComponentPropsWithoutRef<'div'>) => {
  const theme = useTheme();

  const plugins = [...rehypePlugins];

  if (enableRaw) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    plugins.push(rehypeRaw as any);
  }

  const cls = clsx(
    'pre:max-w-[55%] markdown prose',
    'markdown-prose text-foreground',
    props.className
  );

  return (
    <div
      {...props}
      className={`${cls} ${theme.isDark ? 'code-dark prose-invert' : 'code-light'}`}
    >
      <ErrorBoundary fallback={<p>{props.children}</p>}>
        <MarkdownToJSX
          options={{
            overrides: {
              pre: CodeComponent,
            },
          }}
        >
          {props.children}
        </MarkdownToJSX>
      </ErrorBoundary>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CodeComponent = (props: any) => {
  const children = props.children;
  const language = (children.props.className || '').replace('lang-', '');

  const { data: highlighter } = useQuery({
    queryKey: ['codeToHtml', language],
    queryFn: async () => {
      return getSingletonHighlighter({
        themes: ['github-dark', 'github-light'],
        langs: [language],
      });
    },
  });

  if (!highlighter) {
    return (
      <pre>
        <code>{children.props.children}</code>
      </pre>
    );
  }

  const html = highlighter.codeToHtml(children.props.children, {
    lang: language,
    themes: {
      light: 'github-light',
      dark: 'github-dark',
    },
  });

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: html,
      }}
      style={{ maxWidth: 'calc(100% - 16px)' }}
    ></div>
  );
};
