'use client';

import { cva, type VariantProps } from 'class-variance-authority';
import clsx from 'clsx';
import Link from 'next/link';
import type { ComponentPropsWithoutRef } from 'react';
import React from 'react';
import { cn } from '~/lib/utils';
import { BADGE_CATALYST_COLORS } from './badge-catalyst-colors';
import { TouchTarget } from './button';

const badgeVariants = cva(
  'inline-flex items-center rounded-md font-medium forced-colors:outline',
  {
    variants: {
      color: BADGE_CATALYST_COLORS,
      size: {
        default: 'gap-x-1.5 px-1.5 py-0.5 text-xs/5 sm:text-xs/5',
        lg: 'gap-x-2 px-2 py-1 text-sm/5',
        xs: 'gap-x-1 px-1 py-0.5 text-xs/5',
      },
    },
    defaultVariants: {
      color: 'zinc',
      size: 'default',
    },
  }
);

type BadgeVariantProps = VariantProps<typeof badgeVariants>;

export type BadgeProps = BadgeVariantProps & React.ComponentPropsWithoutRef<'span'>;

export function Badge({
  color = 'zinc',
  size = 'default',
  className,
  ...props
}: BadgeProps) {
  return (
    <span {...props} className={cn(badgeVariants({ color, size }), className)} />
  );
}

export const BadgeButton = React.forwardRef(function BadgeButton(
  {
    color,
    size,
    className,
    children,
    badgeClassName,
    ...props
  }: BadgeVariantProps & { children: React.ReactNode; badgeClassName?: string } & (
      | ComponentPropsWithoutRef<'button'>
      | React.ComponentPropsWithoutRef<typeof Link>
    ),
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const classes = clsx(
    className,
    'group relative inline-flex rounded-md focus:outline-none data-[focus]:outline data-[focus]:outline-2 data-[focus]:outline-offset-2 data-[focus]:outline-blue-500'
  );

  return 'href' in props ? (
    <Link
      {...props}
      className={classes}
      ref={ref as React.ForwardedRef<HTMLAnchorElement>}
    >
      <TouchTarget>
        <Badge size={size} color={color} className={badgeClassName}>
          {children}
        </Badge>
      </TouchTarget>
    </Link>
  ) : (
    <button {...props} className={classes} ref={ref}>
      <TouchTarget>
        <Badge size={size} color={color} className={badgeClassName}>
          {children}
        </Badge>
      </TouchTarget>
    </button>
  );
});
