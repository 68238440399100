import { useMedia } from 'react-use';

export const tailwindMediaQuery = {
  sm: '(min-width: 640px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 1024px)',
  xl: '(min-width: 1280px)',
  '2xl': '(min-width: 1536px)',
};

export type TailwindMediaQuery = keyof typeof tailwindMediaQuery;

export const useTailwindMediaQuery = (
  query: TailwindMediaQuery,
  defaultState = false
) => {
  const isMatch = useMedia(tailwindMediaQuery[query], defaultState);

  return isMatch;
};
