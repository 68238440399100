'use client';

import type { MuxPlayerProps } from '@mux/mux-player-react';
import MuxPlayer from '@mux/mux-player-react';
import { useQuery } from '@tanstack/react-query';
import { usePanelStore } from 'app/[schoolId]/(lessons)/courses/[productId]/_components/side-panel/side-panel.store';
import { Bot, Captions } from 'lucide-react';
import type { ComponentRef } from 'react';
import { forwardRef, useEffect, useRef } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { useLocalStorage } from 'react-use';
import { z } from 'zod';
import { AspectRatio } from '~/components/ui/aspect-ratio';
import { Button } from '~/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '~/components/ui/dialog';
import { Tooltip } from '~/components/ui/tooltip';
import { Typo } from '~/components/ui/typography';
import { useAIChatFormValue } from '~/features/ai-chat/AIChatForm';
import {
  CinemaViewer,
  CinemaViewerActions,
  CinemaViewerContainer,
  CinemaViewerToggle,
} from '~/features/cinema/CinemaViewer';
import { Markdown } from '~/features/markdown/Markdown';
import { getSchoolIdClient } from '~/lib/school/getSchoolIdClient';

export type ValidChapter = {
  startTime: number;
  value: string;
};

type MuxVideoProps = {
  id: string;
  poster?: string;
  title?: string;
  className?: string;
  autoPlay?: boolean;
  chapters?: ValidChapter[];
};

const VideoSchema = z.object({
  resume: z.string(),
  chapters: z.array(
    z.object({
      startTime: z.number(),
      value: z.string(),
    })
  ),
  transcript: z.array(
    z.object({
      start: z.number(),
      text: z.string(),
    })
  ),
});
// Add chapters
export default function MuxVideo({
  id,
  poster,
  title,
  autoPlay,
  chapters,
}: MuxVideoProps) {
  const muxRef = useRef<ComponentRef<typeof MuxPlayer>>(null);
  const query = useQuery({
    queryKey: ['video', id],
    queryFn: async () => {
      const result = await fetch(`/api/schools/${getSchoolIdClient()}/videos/${id}`);
      const data = VideoSchema.parse(await result.json());
      return data;
    },
  });

  return (
    <CinemaViewer>
      <CinemaViewerContainer>
        <CinemaViewerActions>
          <Typo variant="muted">{title ?? 'Video'}</Typo>
          <div className="flex-1"></div>

          <CinemaViewerToggle />

          {query.data?.resume ? (
            <Dialog>
              <DialogTrigger asChild>
                <Button
                  variant="outline"
                  className="size-7 p-0 text-muted-foreground hover:text-foreground"
                >
                  <Captions size={14} />
                </Button>
              </DialogTrigger>
              <DialogContent className="max-h-[90vh] max-w-2xl overflow-auto">
                <DialogHeader>
                  <DialogTitle>Transcript courses</DialogTitle>
                </DialogHeader>
                <div>
                  <Markdown>{query.data.resume}</Markdown>
                </div>
              </DialogContent>
            </Dialog>
          ) : null}
          {query.data?.transcript ? (
            <Tooltip title="Ask AI About the video.">
              <Button
                onClick={() => {
                  useAIChatFormValue.getState().setVideo({
                    id,
                    title: title,
                  });

                  usePanelStore.setState({ panel: 'ai' });
                }}
                variant="outline"
                className="size-7 p-0 text-muted-foreground hover:text-foreground"
              >
                <Bot size={14} />
              </Button>
            </Tooltip>
          ) : null}
        </CinemaViewerActions>
        <AspectRatio ratio={16 / 9}>
          <MuxPlayerCustom
            style={{
              width: '100%',
            }}
            theme=""
            ref={muxRef}
            streamType="on-demand"
            poster={poster}
            playbackId={id}
            accentColor="hsl(var(--primary))"
            autoPlay={autoPlay}
            chapters={chapters ?? query.data?.chapters}
          />
        </AspectRatio>
      </CinemaViewerContainer>
    </CinemaViewer>
  );
}

export const MuxPlayerCustom = forwardRef<
  ComponentRef<typeof MuxPlayer>,
  MuxPlayerProps & {
    chapters?: ValidChapter[];
  }
>((props, ref) => {
  const [savedRate, setSavedRate] = useLocalStorage('MuxPlayerCustom-mux-rate', 1);
  const muxRef = useRef<ComponentRef<typeof MuxPlayer>>(null);

  useEffect(() => {
    if (muxRef.current && props.chapters) {
      void muxRef.current.addChapters(
        props.chapters as unknown as {
          startTime: number;
          endTime: number;
          value: string;
        }[]
      );
    }
  }, [props.chapters]);

  return (
    <MuxPlayer
      {...props}
      ref={mergeRefs([muxRef, ref])}
      playbackRates={[0.7, 0.9, 1, 1.25, 1.5, 1.75, 2, 2.5]}
      playbackRate={savedRate}
      onRateChange={() => {
        const newRate = muxRef.current?.playbackRate ?? 1;
        setSavedRate(newRate);
      }}
    />
  );
});

MuxPlayerCustom.displayName = 'MuxPlayerCustom';
