/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef } from 'react';

type EventCallback<T extends any[]> = (...args: T) => void;

export const useEvent = <T extends any[]>(
  callback: EventCallback<T>
): EventCallback<T> => {
  const ref = useRef<EventCallback<T> | null>(null);

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  // Use useCallback to memoize the returned function
  const triggerEvent = useCallback((...args: T) => {
    ref.current?.(...args);
  }, []);

  return triggerEvent;
};
