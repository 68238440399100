import rehypeShiki from '@shikijs/rehype';
import {
  transformerMetaHighlight,
  transformerMetaWordHighlight,
  transformerNotationDiff,
  transformerNotationErrorLevel,
  transformerNotationFocus,
  transformerNotationHighlight,
  transformerNotationWordHighlight,
} from '@shikijs/transformers';
import rehypeExternalLinks from 'rehype-external-links';
import rehypeSlug from 'rehype-slug';
import remarkGfm from 'remark-gfm';
import type { ShikiTransformer } from 'shiki/core';
import type { PluggableList } from 'unified';

const getFileName = (raw?: string): string | null => {
  if (!raw) return null;

  const items = raw.split(' ');
  for (const item of items) {
    const splitItem = item.split('.');

    if (splitItem.length === 1) continue;

    const extension = splitItem.at(-1);

    if (extension?.length === 0) continue;

    return splitItem.join('.');
  }
  return null;
};

const transformerMetadataLanguageClassName = (): ShikiTransformer => {
  let language = 'js';
  let fileName: string | null = null;

  return {
    preprocess(tree, fileInfo) {
      language = fileInfo.lang;
      fileName = getFileName(fileInfo.meta?.__raw);
    },
    code() {
      this.addClassToHast(this.pre, `language-${language}`);
      this.pre.properties.dataLanguage = language;

      if (fileName) {
        this.addClassToHast(this.pre, `filename-${fileName}`);
        this.pre.properties.dataFileName = fileName;
      }
    },
  };
};

const shikiTransformer: ShikiTransformer[] = [
  transformerNotationDiff(),
  transformerNotationHighlight(),
  transformerNotationWordHighlight(),
  transformerNotationFocus(),
  transformerMetaHighlight(),
  transformerNotationErrorLevel(),
  transformerMetaWordHighlight(),
];

const shikiPlugin = [
  rehypeShiki,
  {
    themes: {
      light: 'github-light',
      dark: 'github-dark',
    },
    transformers: [...shikiTransformer, transformerMetadataLanguageClassName()],
  },
] satisfies PluggableList[number];

export const rehypePlugins = [
  rehypeSlug,
  [
    rehypeExternalLinks,
    {
      target: '_blank',
    },
  ],
  shikiPlugin,
] satisfies PluggableList;

export const remarkPlugins = [remarkGfm] as PluggableList;
