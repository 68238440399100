import { SandpackPreview, SandpackStack } from '@codesandbox/sandpack-react';
 
import * as Tabs from '@radix-ui/react-tabs';
import clsx from 'clsx';
import { useState } from 'react';
import { SandpackConsoleStyled } from './SandpackConsoleStyled';

type Tab = 'console' | 'preview';

export const SandpackPlaygroundConsoleAndPreview = ({
  defaultTab,
}: {
  defaultTab: Tab;
}) => {
  const [value, setValue] = useState<Tab>(defaultTab);

  return (
    <Tabs.Root
      value={value}
      onValueChange={(v) => setValue(v as Tab)}
      className="not-prose h-full"
      defaultValue={defaultTab}
      asChild
    >
      <SandpackStack>
        <Tabs.List
          className="-mb-px flex min-h-[40px] items-stretch px-2"
          style={{
            borderBottom: '1px solid var(--sp-colors-surface2)',
            background: 'var(--sp-colors-surface1)',
          }}
        >
          <Tabs.Trigger value="preview" className="outline-none">
            <span
              className="px-2"
              style={{
                color:
                  value === 'preview'
                    ? 'var(--sp-colors-accent)'
                    : 'var(--sp-colors-clickable)',
              }}
            >
              Preview
            </span>
          </Tabs.Trigger>
          <Tabs.Trigger value="console" className="outline-none">
            <span
              className="px-2"
              style={{
                color:
                  value === 'console'
                    ? 'var(--sp-colors-accent)'
                    : 'var(--sp-colors-clickable)',
              }}
            >
              Console
            </span>
          </Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content
          forceMount
          className={clsx('sp-stack sp-preview h-full', {
            hidden: value !== 'preview',
          })}
          value="preview"
        >
          <SandpackPreview
            style={{
              height: '100%',
            }}
          />
        </Tabs.Content>
        <Tabs.Content
          forceMount
          className={clsx('sp-stack sp-preview h-full', {
            hidden: value !== 'console',
          })}
          value="console"
        >
          <SandpackConsoleStyled
            style={{
              height: '100%',
            }}
          />
        </Tabs.Content>
      </SandpackStack>
    </Tabs.Root>
  );
};
