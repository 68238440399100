import { useActiveCode, useSandpack } from '@codesandbox/sandpack-react';
import clsx from 'clsx';
import {
  Copy,
  Maximize2,
  Minimize2,
  Paintbrush,
  RotateCcw,
  Trash2,
} from 'lucide-react';

// @ts-expect-error - No types
import type { CodeMirrorRef } from '@codesandbox/sandpack-react/components/CodeEditor/CodeMirror';

import prettier from 'prettier/standalone';
import { useEffect, type RefObject } from 'react';
import { toast } from 'sonner';
import { useMediaQuery } from 'usehooks-ts';
import { Tooltip } from '~/components/ui/tooltip';
import { useEvent } from '~/lib/hooks/useEvent';
import { logger } from '~/lib/logger/logger';
import styles from './SandPackPlaygroundPrettierButton.module.css';

export const SandPackPlaygroundResetButton = () => {
  const { sandpack } = useSandpack();

  const onReset = () => {
    sandpack.resetAllFiles();
  };

  return (
    <Tooltip title="Reset">
      <button onClick={onReset} className={clsx('rounded-md p-1', styles.button)}>
        <RotateCcw
          style={{ color: 'var(--sp-colors-clickable)', left: 1 }}
          className="relative size-4"
        />
      </button>
    </Tooltip>
  );
};

const getParser = (fileName: string) => {
  const extension = fileName.split('.').pop() ?? '';
  if (['tsx', 'ts'].includes(extension)) {
    return 'typescript';
  }
  if (['jsx', 'js'].includes(extension)) {
    return 'babel';
  }
  if (extension === 'html') {
    return 'html';
  }
  if (['css', 'scss'].includes(extension)) {
    return 'scss';
  }
  return undefined;
};

const getPlugins = async (fileName: string) => {
  const extension = fileName.split('.').pop() ?? '';
  if (['tsx', 'ts', 'js', 'jsx'].includes(extension)) {
    const typescript = await import('prettier/plugins/typescript');
    const estree = await import('prettier/plugins/estree');
    const babel = await import('prettier/plugins/babel');
    return [typescript.default, estree.default, babel.default];
  } else if (fileName.endsWith('.html')) {
    const html = await import('prettier/plugins/html');
    return [html.default];
  } else if (fileName.endsWith('.css') || fileName.endsWith('.scss')) {
    const css = await import('prettier/plugins/postcss');
    logger.debug('RETURN .CSS PLUGIN');
    return [css.default];
  } else {
    return [];
  }
};

export const SandPackPlaygroundPrettierButton = ({
  codemirrorInstance,
  containerRef,
}: {
  codemirrorInstance: RefObject<CodeMirrorRef>;
  containerRef: RefObject<HTMLDivElement>;
}) => {
  const { sandpack } = useSandpack();
  const activeCode = useActiveCode();

  const onPrettier = useEvent(async () => {
    const parser = getParser(sandpack.activeFile);

    if (!parser) {
      toast.error('Prettier is not supported for this file type');
      return;
    }

    const plugins = await getPlugins(sandpack.activeFile);

    const formatted = await prettier.format(activeCode.code, {
      parser: getParser(sandpack.activeFile),
      plugins: plugins,
    });

    const cmInstance = codemirrorInstance.current?.getCodemirror();

    if (!cmInstance) {
      return;
    }
    const trans = cmInstance.state.update({
      selection: cmInstance.state.selection,
      changes: {
        from: 0,
        to: cmInstance.state.doc.length,
        insert: formatted,
      },
    });

    cmInstance.update([trans]);

    sandpack.updateFile(sandpack.activeFile, formatted);
  });

  // if the container ref press CMD + s
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.metaKey && e.key === 's') {
        onPrettier();
      }
    };

    containerRef.current?.addEventListener('keydown', onKeyDown);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      containerRef.current?.removeEventListener('keydown', onKeyDown);
    };
  }, [containerRef, onPrettier]);

  return (
    <Tooltip title="Prettier">
      <button onClick={onPrettier} className={clsx('rounded-md p-1', styles.button)}>
        <Paintbrush
          style={{ color: 'var(--sp-colors-clickable)', left: 1 }}
          className="relative size-4"
        />
      </button>
    </Tooltip>
  );
};

export const SandpackPlaygroundResizeButton = ({
  onClick,
  enabled,
}: {
  onClick: () => void;
  enabled: boolean;
}) => {
  const isDesktop = useMediaQuery('(min-width: 800px');

  if (!isDesktop) {
    return null;
  }

  return (
    <Tooltip title="Expand">
      <button onClick={onClick} className={clsx('rounded-md p-1', styles.button)}>
        {enabled ? (
          <Minimize2
            style={{ color: 'var(--sp-colors-clickable)', left: 1 }}
            className="relative size-4"
          />
        ) : (
          <Maximize2
            style={{ color: 'var(--sp-colors-clickable)', left: 1 }}
            className="relative size-4"
          />
        )}
      </button>
    </Tooltip>
  );
};

export const SandpackConsoleRefresh = ({ onClick }: { onClick: () => void }) => {
  return (
    <Tooltip title="Refresh and delete all logs.">
      <button onClick={onClick} className={clsx('rounded-md p-1', styles.button)}>
        <Trash2
          style={{ color: 'var(--sp-colors-clickable)', left: 1 }}
          className="relative size-4"
        />
      </button>
    </Tooltip>
  );
};

export const SandpackConsoleDisableDuplication = ({
  onClick,
  enabled,
}: {
  onClick: () => void;
  enabled: boolean;
}) => {
  return (
    <Tooltip title="StrictMode may duplicate all logs. If enabled, we-will disable duplication.">
      <button
        onClick={onClick}
        className={clsx('rounded-md p-1', styles.button, {
          [styles.enabled]: enabled,
        })}
      >
        <Copy
          style={{ color: 'var(--sp-colors-clickable)', left: 1 }}
          className="relative size-4"
        />
      </button>
    </Tooltip>
  );
};

export const SandpackConsoleResetOnPreviewRestart = ({
  onClick,
  enabled,
}: {
  onClick: () => void;
  enabled: boolean;
}) => {
  return (
    <Tooltip title="If enabled, when the preview is updated the log will be destroyed.">
      <button
        onClick={onClick}
        className={clsx('rounded-md p-1', styles.button, {
          [styles.enabled]: enabled,
        })}
      >
        <RotateCcw
          style={{ color: 'var(--sp-colors-clickable)', left: 1 }}
          className="relative size-4"
        />
      </button>
    </Tooltip>
  );
};
